import "../../../css/index.css";
import Container from "../../Container";

function MainHome() {
  return (
    <>
      <Container />
    </>
  );
}

export default MainHome;
