import '../../../../css/index.css';
import TopAd  from '../../../TopAd';
import Footer from '../.././../Footer';
import NavigationBar from '../../../NavigationBar';
import SectionA from '../../../pageComponents/Account/signIn/section/SectionA';







function SignIn() {
    return (
      <>
        <TopAd/>
        <NavigationBar/>
        <SectionA/>
        <Footer/>
      </>
    );
  }
  
  export default SignIn;
