import '../css/signin-section.css';

import React from 'react'



const SectionA = (props) => {
  return (
      <>
        <h1>welcome {props.username}</h1>
      </>
  )
}

export default SectionA
